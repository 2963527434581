@import './antComponentOverrides.less';

// Simple resets
* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: @text-base-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// TYPOGRAPHY
.display-lg {
  font-family: @text-font-family-primary;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  letter-spacing: -0.01em;
  color: @color-neutral-90;
  margin: 0;
}

.display-sm {
  .display-lg;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0;
}

.heading-lg {
  font-family: @text-font-family-primary;
  font-size: 1.375rem;
  line-height: 1.75rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  color: @color-neutral-90;
  margin: 0;
}

.heading-md {
  .heading-lg;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
}

.heading-sm {
  .heading-lg;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
}

.heading-xs {
  .heading-lg;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  margin: 0;
}

.text-primary-base {
  font-family: @text-font-family-primary;
  color: @color-neutral-70;
  font-size: 0.875rem;
  line-height: 1.375rem;
}

.text-primary-base-bold {
  .text-primary-base;
  font-weight: 500;
}

.text-primary-sm {
  .text-primary-base;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.text-primary-sm-bold {
  .text-primary-sm;
  font-weight: 500;
}

// CONTAINERS
.content-fixed {
  margin: auto;
  padding: 1.5rem;
  flex: 1;
  width: 100%;
  min-width: 60rem;
  max-width: 69rem;
}

.content-fixed-wide {
  margin: auto;
  padding: 1.5rem;
  flex: 1;
  width: 100%;
  min-width: 60rem;
  max-width: 80rem;
}

.content-full {
  padding: 1.5rem;
  flex: 1;
  width: 100%;
  min-width: 60rem;
}

.content-full-bleed {
  padding: 0;
  flex: 1;
  width: 100%;
  min-width: 60rem;
}

.card {
  border-radius: @border-radius-default;
  padding: 1.5rem;
  background-color: @color-neutral-0;
  margin-bottom: 1.5rem;
  transition: @transition-duration;
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
}
