@import './antDesignTokenOverrides.less';

// PAGEHEADER
.ant-page-header {
  background: @color-neutral-0;
  color: @color-neutral-70;
  border-bottom: 1px solid @color-neutral-40;
  .ant-page-header-back-button {
    color: @color-blue-50;
    &:hover {
      color: @color-blue-70;
    }
  }
  .ant-page-header-content {
    padding-top: 0;
  }
}

.ant-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}

// TABLE
td.ant-table-column-sort {
  background: var(--color-neutral-0);
}
.ant-table-tbody td,
.ant-table-thead th {
  color: @color-neutral-80 !important;
}
tr.ant-table-expanded-row > td {
  background-color: #fff;
}
.ant-table-wrapper {
  border: 1px solid @color-neutral-30;
  border-radius: @border-radius-default;
}
.ant-table-tbody:last-child > tr:last-child > td {
  border-bottom: none;
}

// STATISTIC
.ant-statistic {
  &:not(:last-child) {
    margin-right: @space-l;
  }

  &-title {
    color: @color-neutral-70;
    font-size: @text-heading-xsmall-font-size;
    font-weight: @text-heading-xsmall-font-weight;
    letter-spacing: @text-heading-xsmall-letter-spacing;
    text-transform: uppercase;
    white-space: nowrap;
    margin: 0.1875rem 0 0.125rem 0;
  }

  &-content {
    color: @color-neutral-90;
    font-size: @text-default-medium-font-size;
    font-weight: @text-default-medium-font-weight;
  }
}

// TAG
.ant-tag {
  // enforce accessible colors w sufficient contrast
  &.ant-tag-green {
    background: @color-green-10;
    border-color: @color-green-50;
    color: @color-green-70;
  }

  &.ant-tag-blue {
    background: @color-blue-10;
    border-color: @color-blue-50;
    color: @color-blue-70;
  }

  &.ant-tag-red {
    background: @color-red-10;
    border-color: @color-red-50;
    color: @color-red-70;
  }

  &.ant-tag-orange {
    background: @color-orange-10;
    border-color: @color-orange-50;
    color: @color-orange-70;
  }
}

// STEPS
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: @color-neutral-40;
}
.ant-steps-item-title {
  width: max-content;
}
.ant-steps-item-active .ant-steps-item-icon {
  margin-bottom: -2px;
}
.ant-steps-item-finish.ant-steps-item-disabled
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: @color-neutral-70;
}
.ant-steps-item-finish.ant-steps-item-disabled .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background-color: @color-neutral-70;
}

// MISC CONTROLS
.ant-picker,
.ant-input,
.ant-dropdown-trigger,
.ant-input-affix-wrapper,
.ant-input-group-addon {
  height: 2rem;
  border-width: @border-width-default;
}

.ant-form-item-label {
  font-weight: bold;
  padding-bottom: 0.25rem !important;
  label {
    white-space: nowrap;
  }
}

.ant-input-affix-wrapper input.ant-input {
  height: auto;
}

.ant-slider-with-marks {
  margin-bottom: auto;
}

// The main Curvo font (IBM Plex Sans) has a fatter line height baked into the font, and it throws off
// Ant's text positioning in buttons, selectors, etc. These overrides attempts to correct that text placement.
.ant-btn,
.ant-pagination-item,
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-steps-item-icon {
  display: inline-flex;
  align-items: center;
  transition: @transition-duration;
  border-width: @border-width-thick;
}
.ant-btn,
.ant-pagination-item,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-steps-item-icon {
  justify-content: center;
}

// the arrow icons in icon buttons had a line height of 1 which was goofing up the vertical alignment; prob related to changing font faces
.ant-btn > .anticon {
  line-height: 0;
}

// Breadcrumb needs better contrast per WCAG guidelines
.ant-breadcrumb-link {
  a {
    color: @color-neutral-70;
    &:hover {
      color: @color-blue-70;
    }
  }
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: @color-blue-70;
  border-color: @color-blue-70;
}

// // Previous overrides - LEAVE THESE DISABLED; we may not need them

// .alt-menu {
//   background-color: @gray10;
//   .ant-dropdown-menu-item {
//     color: white;
//   }

//   .ant-dropdown-menu-item:hover {
//     background-color: #203868;
//   }
// }

// .organizations-tree {
//   margin-top: 18px;
//   font-size: 12px;
//   font-weight: bold;
//   .ant-tree-node-content-wrapper .ant-tree-iconEle {
//     width: auto;
//     margin-right: 4px;
//   }

//   .ant-tree-switcher {
//     width: auto;
//     margin-right: 4px;
//   }
// }

// }

// .crv-filters-bar {
//   margin-bottom: 24px;
// }

// .ant-upload-list {
//   text-align: left;
// }

// .grid-spending [col-id='actions'] .ag-row-group {
//   padding-left: 5px;
// }

// .hide-dragger {
//   display: none;
// }
