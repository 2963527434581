// FONTS
@text-font-family-primary: 'IBM Plex Sans', 'Roboto', sans-serif;

// TYPE
// 3/1/22 Keeping these here for old code that relies on it, but otherwise these are being moved into the various type class defs
// TODO: refactor and get rid of these
@text-display-large-line-height: 2rem;
@text-display-large-font-weight: 400;
@text-display-large-font-size: 1.5rem;
@text-display-small-line-height: 1.75rem;
@text-display-small-font-weight: 400;
@text-display-small-font-size: 1.25rem;
@text-heading-large-line-height: 1.75rem;
@text-heading-large-font-weight: 500;
@text-heading-large-font-size: 1.25rem;
@text-heading-small-line-height: 1.5rem;
@text-heading-small-font-weight: 500;
@text-heading-small-font-size: 1rem;
@text-heading-xsmall-text-transform: uppercase;
@text-heading-xsmall-letter-spacing: 0.03rem;
@text-heading-xsmall-line-height: 1.23rem;
@text-heading-xsmall-font-weight: 500;
@text-heading-xsmall-font-size: 0.75rem;
@text-default-medium-line-height: 1.375rem;
@text-default-medium-font-weight: 500;
@text-default-medium-font-size: 0.875rem;
@text-default-regular-line-height: 1.375rem;
@text-default-regular-font-weight: 400;
@text-default-regular-font-size: 0.875rem;
@text-small-medium-line-height: 1.25rem;
@text-small-medium-font-weight: 500;
@text-small-medium-font-size: 0.75rem;
@text-small-regular-line-height: 1.25rem;
@text-small-regular-font-weight: 400;
@text-small-regular-font-size: 0.75rem;
@text-base-font-size: 16px;

// PAGE SIZES
@page-width-fixed-max: 75rem;
@page-width-fixed-min: 56rem;

// BORDERS
@border-radius-small: 0.125rem;
@border-radius-default: 0.25rem;
@border-width-thin: 1px;
@border-width-default: 1px;
@border-width-thick: 2px;

// SPACERS
@space-xxxl: 6rem;
@space-xxl: 4rem;
@space-xl: 2.5rem;
@space-l: 1.5rem;
@space-m: 1rem;
@space-s: 0.75rem;
@space-xs: 0.5rem;
@space-xxs: 0.25rem;

// SHADOWS
@shadow-1: 0 0.1875rem 0.375rem -0.25rem #0000001f, 0 0.375rem 1rem #00000014, 0 0.5625rem 1.75rem 0.5rem #0000000d;

// TIMING
@transition-duration: 200ms;

// COLORS
@color-neutral-90: #172237;
@color-neutral-80: #293347;
@color-neutral-70: #6f7785;
@color-neutral-60: #8f96a3;
@color-neutral-50: #bdc2ca;
@color-neutral-40: #d9dce2;
@color-neutral-30: #eceef3;
@color-neutral-20: #f3f5f8;
@color-neutral-10: #f6f7f9;
@color-neutral-0: #ffffff;

@color-orange-70: #d34e17;
@color-orange-50: #f4642a;
@color-orange-40: #f8804f;
@color-orange-10: #fff4f0;

@color-red-70: #bd2828;
@color-red-50: #f91f1f;
@color-red-10: #fef5f7;

@color-green-70: #12735a;
@color-green-50: #10a882;
@color-green-10: #f1fdf9;

@color-blue-70: #135bec;
@color-blue-50: #3377ff;
@color-blue-20: #e5edff;
@color-blue-10: #f0f5ff;

// ACCENT COLORS
@color-accent-magenta-70: #c41d7f;
@color-accent-magenta-50: #f759ab;
@color-accent-magenta-10: #fff0f6;
@color-accent-purple-70: #531dab;
@color-accent-purple-50: #9254de;
@color-accent-purple-10: #f9f0ff;
@color-accent-geek-blue-70: #1d39c4;
@color-accent-geek-blue-50: #597ef7;
@color-accent-geek-blue-10: #f0f5ff;
@color-accent-cyan-70: #08979c;
@color-accent-cyan-50: #36cfc9;
@color-accent-cyan-10: #e6fffb;
@color-accent-polar-green-70: #389e0d;
@color-accent-polar-green-50: #73d13d;
@color-accent-polar-green-10: #f6ffed;
@color-accent-lime-70: #7cb305;
@color-accent-lime-50: #a0d911;
@color-accent-lime-10: #fcffe6;
@color-accent-sunrise-yellow-70: #d4b106;
@color-accent-sunrise-yellow-50: #fadb14;
@color-accent-sunrise-yellow-10: #feffe6;
@color-accent-gold-70: #d48806;
@color-accent-gold-50: #ffc53d;
@color-accent-gold-10: #fffbe6;
@color-accent-sunset-orange-70: #d46b08;
@color-accent-sunset-orange-50: #ffa940;
@color-accent-sunset-orange-10: #fff7e6;
@color-accent-volcano-70: #d4380d;
@color-accent-volcano-50: #ff7a45;
@color-accent-volcano-10: #fff2e8;
@color-accent-dust-red-70: #cf1322;
@color-accent-dust-red-50: #ff4d4f;
@color-accent-dust-red-10: #fff1f0;
@color-accent-daybreak-blue-70: #096dd9;
@color-accent-daybreak-blue-50: #40a9ff;
@color-accent-daybreak-blue-10: #e6f7ff;

// TEST: use CSS custom props instead of separate tokens.ts file for Styled Components. Single source of truth this way:
:root {
  --color-neutral-90: @color-neutral-90;
  --color-neutral-80: @color-neutral-80;
  --color-neutral-70: @color-neutral-70;
  --color-neutral-60: @color-neutral-60;
  --color-neutral-50: @color-neutral-50;
  --color-neutral-40: @color-neutral-40;
  --color-neutral-30: @color-neutral-30;
  --color-neutral-20: @color-neutral-20;
  --color-neutral-10: @color-neutral-10;
  --color-neutral-0: @color-neutral-0;

  --color-orange-70: @color-orange-70;
  --color-orange-50: @color-orange-50;
  --color-orange-40: @color-orange-40;
  --color-orange-10: @color-orange-10;

  --color-red-70: @color-red-70;
  --color-red-50: @color-red-50;
  --color-red-10: @color-red-10;

  --color-green-70: @color-green-70;
  --color-green-50: @color-green-50;
  --color-green-10: @color-green-10;

  --color-blue-70: @color-blue-70;
  --color-blue-50: @color-blue-50;
  --color-blue-20: @color-blue-20;
  --color-blue-10: @color-blue-10;

  // ACCENT COLORS
  --color-accent-magenta-70: @color-accent-magenta-70;
  --color-accent-magenta-50: @color-accent-magenta-50;
  --color-accent-magenta-10: @color-accent-magenta-10;
  --color-accent-purple-70: @color-accent-purple-70;
  --color-accent-purple-50: @color-accent-purple-50;
  --color-accent-purple-10: @color-accent-purple-10;
  --color-accent-geek-blue-70: @color-accent-geek-blue-70;
  --color-accent-geek-blue-50: @color-accent-geek-blue-50;
  --color-accent-geek-blue-10: @color-accent-geek-blue-10;
  --color-accent-cyan-70: @color-accent-cyan-70;
  --color-accent-cyan-50: @color-accent-cyan-50;
  --color-accent-cyan-10: @color-accent-cyan-10;
  --color-accent-polar-green-70: @color-accent-polar-green-70;
  --color-accent-polar-green-50: @color-accent-polar-green-50;
  --color-accent-polar-green-10: @color-accent-polar-green-10;
  --color-accent-lime-70: @color-accent-lime-70;
  --color-accent-lime-50: @color-accent-lime-50;
  --color-accent-lime-10: @color-accent-lime-10;
  --color-accent-sunrise-yellow-70: @color-accent-sunrise-yellow-70;
  --color-accent-sunrise-yellow-50: @color-accent-sunrise-yellow-50;
  --color-accent-sunrise-yellow-10: @color-accent-sunrise-yellow-10;
  --color-accent-gold-70: @color-accent-gold-70;
  --color-accent-gold-50: @color-accent-gold-50;
  --color-accent-gold-10: @color-accent-gold-10;
  --color-accent-sunset-orange-70: @color-accent-sunset-orange-70;
  --color-accent-sunset-orange-50: @color-accent-sunset-orange-50;
  --color-accent-sunset-orange-10: @color-accent-sunset-orange-10;
  --color-accent-volcano-70: @color-accent-volcano-70;
  --color-accent-volcano-50: @color-accent-volcano-50;
  --color-accent-volcano-10: @color-accent-volcano-10;
  --color-accent-dust-red-70: @color-accent-dust-red-70;
  --color-accent-dust-red-50: @color-accent-dust-red-50;
  --color-accent-dust-red-10: @color-accent-dust-red-10;
  --color-accent-daybreak-blue-70: @color-accent-daybreak-blue-70;
  --color-accent-daybreak-blue-50: @color-accent-daybreak-blue-50;
  --color-accent-daybreak-blue-10: @color-accent-daybreak-blue-10;
}
